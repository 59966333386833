import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import CreateOrder from './CreateOrder'
import OrderDetails from './OrderDetails'
import OrderManagementContent from './OrderManagementContent'

const OrderManagement = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={< OrderManagementContent />} />
                <Route path="/orderDetails" element={<OrderDetails />} />
                <Route path="/createOrder" element={<CreateOrder />} />
            </Routes>
            <Outlet />
        </>

    )
}

export default OrderManagement