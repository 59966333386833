import http from "../../utils/utils";
import { GET_RIDERS, GET_RIDERS_FAIL, GET_RIDERS_SUCCESSFUL, GET_AGENCIES, GET_AGENCIES_SUCCESSFUL, GET_AGENCIES_FAIL, GET_USERS, GET_USERS_FAIL, GET_USERS_SUCCESSFUL, GET_VENDORS, GET_VENDORS_FAIL, GET_VENDORS_SUCCESSFUL } from "../Types";




export const getUsers = (order_status) => {
    return dispatch => {
        dispatch({ type: GET_USERS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/users`,)
                const data = res
                // console.log('get Orders ', res)
                dispatch({ type: GET_USERS_SUCCESSFUL, payload: res });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: GET_USERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};



export const getVendors = () => {
    return dispatch => {
        dispatch({ type: GET_VENDORS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/businesses`,)
                const data = res
                // console.log('get Orders ', res)
                dispatch({ type: GET_VENDORS_SUCCESSFUL, payload: res });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: GET_VENDORS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};



export const getRiders = () => {
    return dispatch => {
        dispatch({ type: GET_RIDERS })
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/users?user_type=${'riders'}&limit=500`,)
                const data = res
                // console.log('get Orders ', res)
                dispatch({ type: GET_RIDERS_SUCCESSFUL, payload: res });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: GET_RIDERS_FAIL, payload: error });
                reject(error);
            }
        });
    };
};



export const getAgencies = () => {
    return dispatch => {
        dispatch({ type: GET_AGENCIES})
        return new Promise(async (resolve, reject) => {
            try {
                const res = await http.get(`admin/user/agency/all`)
                const data = res
                console.log('get agencies ', res)
                dispatch({ type: GET_AGENCIES_SUCCESSFUL, payload: res });
                resolve(data);
            } catch (error) {
                // console.log('get orders error', error)
                dispatch({ type: GET_AGENCIES_FAIL, payload: error });
                reject(error);
            }
        });
    };
};


