import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
    const { loggedIn } = useSelector(state => state.auth);
    const accessToken = localStorage.getItem('accessToken');
    console.log(loggedIn, accessToken)
    if (!accessToken) {
        // User is not authenticated
        return <Navigate to="/login" />;
    }
    return children;
};

export const ProtectedAuthRoute = ({ children }) => {
    const { token } = useSelector(state => state.auth);
    const accessToken = localStorage.getItem('accessToken');
    console(token ,accessToken)

    if (token || !accessToken) {
        // User is not authenticated or no accessToken in localStorage
        return <Navigate to="/" />;
    }
    return children;
};
