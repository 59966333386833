import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import BackComponent from '../../../utils/BackComponent'
import MultiButton from '../../../utils/MultiButton'
import Navigation from '../../../widgets/Navigation'
import '../../../assets/styles/orderManagementStyles.css';
import CardsWithHeader from '../../../components/all/CardsWithHeader'
import { AboutUserIcon, BillingIcon, MealSetupIcon, Message, RiderIcon } from '../../../assets/images/icons'
import { formatAmount } from '../../../utils/Helpers'
import ConfirmPaymentModal from '../../../components/dashboard/orderManagement/ConfirmPaymentModal'
import AssignRiderModal from '../../../components/dashboard/orderManagement/AssignRiderModal'
import AssignAgencyModal from '../../../components/dashboard/orderManagement/AssignAgencyModal'
import CancelOrderModal from '../../../components/dashboard/orderManagement/CancelOrderModal'
import { useDispatch, useSelector } from 'react-redux'
import { getOrdersById } from '../../../redux/actions/OrdersAction'
import Skeleton from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'


function getStatusFromTimeDifference(timeDifference) {
    const roundedTime = timeDifference.toFixed(2); // Round to 2 decimal places
  
    if (timeDifference <= 30) {
        return { status: `Normal ${roundedTime} mins`, color: 'rgba(102, 203, 159, 1)', bgColor: 'rgba(222, 255, 238, 1)', time: roundedTime };
    } else if (timeDifference > 30 && timeDifference <= 40) {
        return { status: `Potential Delay ${roundedTime} mins`, color: 'rgba(255, 213, 79, 1)', bgColor: 'rgba(255, 248, 225, 1)', time: roundedTime };
    } else if (timeDifference > 40 && timeDifference <= 60) {
        return { status: `Delay ${roundedTime} mins`, color: 'rgba(255, 213, 79, 1)', bgColor: 'rgba(255, 248, 225, 1)', time: roundedTime };
    } else if (timeDifference > 60) {
        return { status: `Critical ${roundedTime} mins`, color: 'rgba(241, 96, 99, 1)', bgColor: 'rgba(255, 230, 228, 1)', time: roundedTime };
    } else {
        return { status: `Unknown classification ${roundedTime} mins`, color: 'rgba(241, 96, 99, 1)', bgColor: 'rgba(255, 230, 228, 1)', time: roundedTime };
    }
}
  
const ClassifyOrder = (whenCustomerPaid, whenOrderDelivered, orderStatus) => {
    const currentTime = new Date().getTime();
    const paymentTime = new Date(whenCustomerPaid).getTime();
    const deliveredTime = new Date(whenOrderDelivered).getTime();
    const timeDifference = (currentTime - paymentTime) / 60000; 
    const deliveredTimeDifference = (deliveredTime - paymentTime) / 60000;
  
    if (!whenCustomerPaid) {
        return { status: `Unpaid`, color: 'rgba(166, 183, 212, 1)', bgColor: 'rgba(235, 242, 250, 1)' };
    }
    if (whenOrderDelivered) {
        return { status: `Delivered ${deliveredTimeDifference.toFixed(2)} mins`, color: '#000', bgColor: 'rgba(240, 241, 242, 1)' };
    } 
    if(orderStatus === 'ORDER_CANCELLED') {
        return { status: `Cancelled`, color: '#000', bgColor: 'rgba(240, 241, 242, 1)' };
    } 
    return getStatusFromTimeDifference(timeDifference);
};



const OrderDetails = (props) => {

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1430px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1430px)' })
    const params = useLocation()

    const [state, setState] = useState({
        confirmModal: false, assignModal: false, cancelModal: false, agencyModal:false
    })
    const { singleOrder, gettingSingleOrder } = useSelector(state => state.orders)

    const recievingCustomerName = singleOrder?.data?.order?.recievingCustomerName;
    const recievingCustomerPhone = singleOrder?.data?.order?.recievingCustomerPhone;
    const destinationAddress = singleOrder?.data?.order?.shipping?.destinationAddress;
    const whenCustomerPaid = singleOrder?.data?.order?.whenCustomerPayedForOrder || null;
    const whenOrderDelivered = singleOrder?.data?.order?.whenOrderGotDelivered || null;
    const orderStatus = singleOrder?.data?.order?.orderStatus || null;
    const orderClassification = ClassifyOrder(whenCustomerPaid, whenOrderDelivered, orderStatus);


    console.log(singleOrder)


    const hideScroll = () => {
        document.body.style.overflow = "hidden"
    }
    const unhideScroll = () => {
        document.body.style.overflow = "unset"
    }

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getOrdersById(params?.state?.id))
    }, [])


    const openConfirmModal = () => {
        setState((prevState) => ({ ...prevState, confirmModal: true }))
        hideScroll()
    }
    const closeConfirmModal = () => {
        setState((prevState) => ({ ...prevState, confirmModal: false }))
        unhideScroll()
    }


    const openAssignModal = () => {
        setState((prevState) => ({ ...prevState, assignModal: true }))
        hideScroll()
    }
    const closeAssignModal = () => {
        setState((prevState) => ({ ...prevState, assignModal: false }))
        unhideScroll()
    }

  const openAssignToAgencyModal = () => {
        setState((prevState) => ({ ...prevState, agencyModal: true }))
        hideScroll()
    }
    const closeAssignToAgencyModal = () => {
        setState((prevState) => ({ ...prevState, agencyModal: false }))
        unhideScroll()
    }


    
    
    const openCancelModal = () => {
        setState((prevState) => ({ ...prevState, cancelModal: true }))
        hideScroll()
    }
    const closeCancelModal = () => {
        setState((prevState) => ({ ...prevState, cancelModal: false }))
        unhideScroll()
    }






    const statusTextSwitch = (status) => {
        switch (status) {
            case 'ORDER_CREATED':
                return 'PENDING'
            case 'ORDER_CONFIRMED':
                return 'CONFIRMED'
            case 'ORDER_AWAITING_ASSIGNMENT':
                return 'AWAITING ASSIGNMENT'
            case 'ORDER_ASSIGNED':
                return 'ASSIGNED'
            case 'ORDER_ACCEPTED':
                return 'ACCEPTED'
            case 'ORDER_REJECTED':
                return 'REJECTED'
            case 'ORDER_PICKED_UP':
                return 'TRANSIT'
            case 'ORDER_DELIVERED':
                return 'DELIVERED'
            case 'ORDER_CANCELLED':
                return 'CANCELLED'
            default:
                return 'CANCELLED'
        }
    }

    const statusBgSwitch = (status) => {
        switch (status) {
            case 'ORDER_CREATED':
                return '#EBF2FA'
            case 'ORDER_CONFIRMED':
                return '#DEFFEE'
            case 'ORDER_AWAITING_ASSIGNMENT':
                return '#DEFFEE'
            case 'ORDER_ASSIGNED':
                return '#E1E8FF'
            case 'ORDER_ACCEPTED':
                return '#DEFFEE'
            case 'ORDER_REJECTED':
                return '#FFE6E4'
            case 'ORDER_PICKED_UP':
                return '#FFF8E1'
            case 'ORDER_DELIVERED':
                return '#DEFFEE'
            case 'ORDER_CANCELLED':
                return '#FFE6E4'
            default:
                return '#FFE6E4'
        }
    }

    const statusTextColorSwitch = (status) => {
        switch (status) {
            case 'ORDER_CREATED':
                return '#A6B7D4'
            case 'ORDER_CONFIRMED':
                return '#66CB9F'
            case 'ORDER_AWAITING_ASSIGNMENT':
                return '#66CB9F'
            case 'ORDER_ASSIGNED':
                return '#4C6FFF'
            case 'ORDER_ACCEPTED':
                return '#66CB9F'
            case 'ORDER_REJECTED':
                return '#F16063'
            case 'ORDER_PICKED_UP':
                return '#FFD54F'
            case 'ORDER_DELIVERED':
                return '#66CB9F'
            case 'ORDER_CANCELLED':
                return '#F16063'
            default:
                return '#F16063'
        }
    }


    const renderButtons = () => {
        if (singleOrder?.data?.order?.paymentStatus === 'PROCESSING' && singleOrder?.data?.order?.orderStatus !== 'ORDER_CANCELLED' && singleOrder?.data?.order?.orderType !== 'ONLINE_ORDER') {
            return (
                <MultiButton title={'Confirm payment'} icon={false} bgColor={'#66BB6A'} onClick={openConfirmModal} height={'40px'} width={'162px'} />

            )
        }
        if (singleOrder?.data?.order?.paymentStatus === 'COMPLETED' && !singleOrder?.data?.order?.riderId && singleOrder?.data?.order?.orderStatus !== 'ORDER_PICKED_UP'
            && singleOrder?.data?.order?.orderStatus !== 'ORDER_CANCELLED' && singleOrder?.data?.order?.orderStatus !== 'ORDER_DELIVERED') {
            return (
                <div className={'flexRow alginCenter'} style={{gap:'16px'}}>
                     <MultiButton title={'Assign to rider'} icon={false} bgColor={'#66BB6A'} onClick={openAssignModal} height={'40px'} width={'144px'} />
                     <MultiButton title={'Assign to agency'} icon={false} bgColor={'#66BB6A'} onClick={openAssignToAgencyModal} height={'40px'} width={'160px'} />
                </div>
               
            )
        }
        if (singleOrder?.data?.order?.orderStatus === 'ORDER_ASSIGNED' || singleOrder?.data?.order?.orderStatus === 'ORDER_REJECTED' || singleOrder?.data?.order?.orderStatus === 'ORDER_ACCEPTED' || singleOrder?.data?.order?.orderStatus === 'ORDER_PICKED_UP') {
            return (
                <div className={'flexRow alginCenter'} style={{gap:'16px'}}>
                    <MultiButton title={'Reassign to rider'} icon={false} bgColor={'#66BB6A'} onClick={openAssignModal} height={'40px'} width={'144px'} />
                    <MultiButton title={'Reassign to agency'} icon={false} bgColor={'#66BB6A'} onClick={openAssignToAgencyModal} height={'40px'} width={'160px'} />
                </div>
            )
        }
    }


    const paymentMethod = singleOrder?.data?.order?.transactionDetails[singleOrder?.data?.order?.transactionDetails.length - 1]




    return (
        <>

            {isDesktopOrLaptop &&
                <>
                    <Navigation />
                    <main className={'orderManagementContainer'}>
                        <section className={'flexRow alignCenter justifyBetween miniNav'}>
                            <div className={'flexRow alignCenter'}>
                                <BackComponent />
                                {gettingSingleOrder ? <>
                                    <Skeleton width={'200px'} />
                                </>
                                    : <>
                                        <h3 className={'boldText f24'} style={{ margin: '0 16px 0 24px' }}>{singleOrder?.data?.order?.id}</h3>
                                        <p className={' orderStatusContainer textCenter f14 regularText'}
                                            style={{ backgroundColor: statusBgSwitch(singleOrder?.data?.order?.orderStatus), color: statusTextColorSwitch(singleOrder?.data?.order?.orderStatus), width: 'auto' }}>
                                            {statusTextSwitch(singleOrder?.data?.order?.orderStatus)}
                                        </p>
                                    </>
                                }
                            </div>
                            <div className={'flexRow alignCenter flexWrap'}>
                                {singleOrder?.data?.order?.orderStatus !== 'ORDER_CANCELLED' &&
                                    <div style={{ marginRight: '16px' }}>
                                        <MultiButton title={'Cancel order'} icon={false} bgColor={'#F96868'} onClick={openCancelModal} height={'40px'} width={'128px'} />
                                    </div>}
                                <div>
                                    {renderButtons()}
                                </div>
                            </div>
                        </section>
                        <section className={'orderDetailsGrid'}>
                            {gettingSingleOrder ? <>

                                <Skeleton height={'300px'} style={{ borderRadius: '8px' }} />
                                <Skeleton height={'300px'} style={{ borderRadius: '8px' }} />
                                <Skeleton height={'300px'} style={{ borderRadius: '8px' }} />
                                <Skeleton height={'300px'} style={{ borderRadius: '8px' }} />

                            </> : <>
                                <CardsWithHeader title={'About customer'} icon={AboutUserIcon}>
                                    <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Fullname</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.user.firstName + ' ' + singleOrder?.data?.order?.user.lastName || 'No name'}</p>
                                    </div>
                                    <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Mobile number</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.user.phoneNumber || 'No number'}</p>
                                    </div>
                                    {/* <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>State of residence</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{stateOfResidence}</p>
                                    </div> */}
                                    <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Delivery address</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{destinationAddress}</p>
                                    </div>
                                    {recievingCustomerName && (
                                        <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                            <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Receivers name</p>
                                            <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.recievingCustomerName}</p>
                                        </div>
                                    )}
                                    {recievingCustomerPhone && (
                                        <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                            <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Receivers mobile number</p>
                                            <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.recievingCustomerPhone}</p>
                                        </div>
                                    )}
                                    {(recievingCustomerName || recievingCustomerPhone) && (
                                        <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                            <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Receivers address</p>
                                            <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{destinationAddress}</p>
                                        </div>
                                    )}
                                    <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Device</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.deviceUsed}</p>
                                    </div>
                                </CardsWithHeader>
                                {singleOrder?.data?.order?.rider &&
                                    <CardsWithHeader title={'About Rider'} icon={RiderIcon}>
                                        <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                            <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Fullname</p>
                                            <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.rider?.user?.firstName + ' ' + singleOrder?.data?.order?.rider?.user?.lastName || 'No name'}</p>
                                        </div>
                                        <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                            <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Mobile number</p>
                                            <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.rider?.user?.phoneNumber || 'No number'}</p>
                                        </div>
                                        <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                            <p className={'f16 boldText'} style={{ color: ' #38383A' }}>State of residence</p>
                                            <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.rider?.riderOperationLocation}</p>
                                        </div>
                                        {/* <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Delivery address</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.shipping?.destinationAddress || 'No address'}</p>
                                    </div> */}
                                    </CardsWithHeader>}
                                <CardsWithHeader title={'Meal setup'} icon={MealSetupIcon} >
                                    {singleOrder?.data?.order?.orderItems?.map((item, index) => {
                                        return (
                                            <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }} key={index}>
                                                <div>
                                                    <p className={'f16 boldText'}>{item?.name}</p>
                                                    <p className={'f14 semiBoldText'} style={{ color: '#919294' }}>₦{formatAmount(item?.price)}</p>
                                                </div>
                                                <div>
                                                    <p className={'f16 boldText'} style={{ color: '#919294' }}>x{item?.quantity}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div style={{ marginBottom: '16px', marginTop: '20px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Delivery status</p>
                                        <div style={{
                                            color: orderClassification.color, backgroundColor: orderClassification.bgColor,
                                            width: 'auto', textAlign: 'center', padding: '3px 6px', borderRadius: '8px', marginTop: '8px',maxWidth:'140px' 
                                        }}>
                                            <p className={'semiBoldText f14'} style={{ color: orderClassification.color }}>{orderClassification.status}</p>
                                        </div>

                                    </div>
                                </CardsWithHeader>
                                <CardsWithHeader title={'Billing & Payment'} icon={BillingIcon}>
                                    <div style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Payment method</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{paymentMethod?.paymentMode} - {paymentMethod?.paymentProvider}</p>
                                    </div>
                                    <div style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Payment status</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.paymentStatus}</p>
                                    </div>
                                    <div style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Meal cost</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{formatAmount(singleOrder?.data?.order?.totalPriceWithoutServiceFee)}</p>
                                    </div>
                                    <div style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Service fee</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.serviceFee}%</p>
                                    </div>
                                    <div style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Delivery fee</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{formatAmount(singleOrder?.data?.order?.shipping?.deliveryFee)}</p>
                                    </div>
                                    <div style={{ marginBottom: '16px', borderBottom: ' 0.5px solid #E6E7E9', paddingBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Discount</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{singleOrder?.data?.order?.totalDiscount}</p>
                                    </div>
                                    <div style={{ marginBottom: '16px', }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Grand total</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{formatAmount(Number(singleOrder?.data?.order?.totalItemsPrice) + Number(singleOrder?.data?.order?.shipping?.deliveryFee))}</p>
                                    </div>



                                </CardsWithHeader>
                                <CardsWithHeader title={'Message for restaurant'} icon={Message} class={`${singleOrder?.data?.order?.rider ? 'lastCard' : ''}`}>
                                    <div>
                                        <p className={'f14 semiBoldText'} style={{ marginBottom: "16px", color: "#38383A" }}>Message</p>
                                        <p className={'f16 regularText'} style={{ fontStyle: 'italic', color: '#999CA0' }}>{singleOrder?.data?.order?.comment}</p>
                                    </div>
                                </CardsWithHeader>
                            </>}
                        </section>
                    </main>
                </>
            }
            {isTabletOrMobile &&
                <main style={{ padding: '40px 20px 150px 20px' }}>
                    <section >
                        <div className={'flexRow alignCenter'}>
                            <BackComponent />
                            {gettingSingleOrder ? <>
                                <Skeleton width={'200px'} />
                            </>
                                : <>
                                    <h3 className={'boldText f16'} style={{ margin: '0 16px 0 24px' }}>{singleOrder?.data?.order?.id}</h3>
                                    <p className={' orderStatusContainer textCenter f12 regularText'}
                                        style={{ backgroundColor: statusBgSwitch(singleOrder?.data?.order?.orderStatus), color: statusTextColorSwitch(singleOrder?.data?.order?.orderStatus), width: 'auto' }}>
                                        {statusTextSwitch(singleOrder?.data?.order?.orderStatus)}
                                    </p>
                                </>
                            }
                        </div>
                        <div className={'flexRow alignCenter justifyBetween mobileOrderDetailsButton flexWrap'}>
                        <div style={{marginBottom:'10px'}}>
                                {renderButtons()}
                            </div>
                            {singleOrder?.data?.order?.orderStatus !== 'ORDER_CANCELLED' &&
                                <div style={{ marginRight: '16px' }}>
                                    <MultiButton title={'Cancel order'} icon={false} bgColor={'#F96868'} onClick={openCancelModal} height={'40px'} width={'128px'} />
                                </div>}
                           
                        </div>
                    </section>
                    <section style={{ display: "grid", gridTemplateColumns: '100%', gap: "16px", marginTop: '20px' }}>
                        {gettingSingleOrder ? <>

                            <Skeleton height={'300px'} style={{ borderRadius: '8px' }} />
                            <Skeleton height={'300px'} style={{ borderRadius: '8px' }} />
                            <Skeleton height={'300px'} style={{ borderRadius: '8px' }} />
                            <Skeleton height={'300px'} style={{ borderRadius: '8px' }} />

                        </> : <>
                            <CardsWithHeader title={'About customer'} icon={AboutUserIcon}>
                                <div style={{ marginBottom: '16px' }}>
                                    <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Fullname</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.user.firstName + ' ' + singleOrder?.data?.order?.user.lastName || 'No name'}</p>
                                </div>
                                <div style={{ marginBottom: '16px' }}>
                                    <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Mobile number</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.user.phoneNumber || 'No number'}</p>
                                </div>
                                {/* <div style={{ marginBottom: '16px' }}>
                                    <p className={'f16 boldText'} style={{ color: ' #38383A' }}>State of residence</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{stateOfResidence}</p>
                                </div> */}
                                <div style={{ marginBottom: '16px' }}>
                                    <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Delivery address</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.shipping?.destinationAddress || 'No address'}</p>
                                </div>
                                {recievingCustomerName && (
                                    <div style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Receivers name</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.recievingCustomerName}</p>
                                    </div>

                                )}
                                {recievingCustomerPhone && (
                                    <div style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Receivers mobile number</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.recievingCustomerPhone}</p>
                                    </div>

                                )}
                                {(recievingCustomerName || recievingCustomerPhone) && (
                                    <div style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: '#38383A' }}>Receivers address</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{destinationAddress}</p>
                                    </div>
                                )}
                                <div style={{ marginBottom: '16px' }}>
                                    <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Device</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.deviceUsed}</p>
                                </div>
                            </CardsWithHeader>
                            {singleOrder?.data?.order?.rider &&
                                <CardsWithHeader title={'About Rider'} icon={RiderIcon}>
                                    <div style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Fullname</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.rider?.user?.firstName + ' ' + singleOrder?.data?.order?.rider?.user?.lastName || 'No name'}</p>
                                    </div>
                                    <div style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Mobile number</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.rider?.user?.phoneNumber || 'No number'}</p>
                                    </div>
                                    <div style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>State of residence</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.rider?.riderOperationLocation}</p>
                                    </div>
                                    {/* <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '16px' }}>
                                        <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Delivery address</p>
                                        <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.shipping?.destinationAddress || 'No address'}</p>
                                    </div> */}
                                </CardsWithHeader>}
                            <CardsWithHeader title={'Meal setup'} icon={MealSetupIcon} >
                                {singleOrder?.data?.order?.orderItems?.map((item, index) => {
                                    return (
                                        <div className={'flexRow  justifyBetween'} style={{ marginBottom: '16px' }} key={index}>
                                            <div>
                                                <p className={'f16 boldText'}>{item?.name}</p>
                                                <p className={'f14 semiBoldText'} style={{ color: '#919294' }}>₦{formatAmount(item?.price)}</p>
                                            </div>
                                            <div>
                                                <p className={'f16 boldText'} style={{ color: '#919294' }}>x{item?.quantity}</p>
                                            </div>
                                        </div>
                                    )
                                })}

                                <div style={{ marginBottom: '16px', marginTop: '20px' }}>
                                    <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Delivery status</p>
                                    <div style={{
                                            color: orderClassification.color, backgroundColor: orderClassification.bgColor,
                                            maxWidth: '140px', textAlign: 'center', padding: '3px 6px', borderRadius: '8px', marginTop: '8px'
                                        }}>
                                            <p className={'semiBoldText f14'} style={{ color: orderClassification.color }}>{orderClassification.status}</p>
                                        </div>
                                </div>
                                

                            </CardsWithHeader>
                            <CardsWithHeader title={'Billing & Payment'} icon={BillingIcon}>
                                <div style={{ marginBottom: '16px' }}>
                                    <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Payment method</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{paymentMethod?.paymentMode} - {paymentMethod?.paymentProvider}</p>

                                </div>
                                <div style={{ marginBottom: '16px' }}>
                                    <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Payment status</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.paymentStatus}</p>
                                </div>
                                <div style={{ marginBottom: '16px' }}>
                                    <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Meal cost</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{formatAmount(singleOrder?.data?.order?.totalPriceWithoutServiceFee)}</p>
                                </div>
                                <div style={{ marginBottom: '16px' }}>
                                    <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Service fee</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>{singleOrder?.data?.order?.serviceFee}%</p>
                                </div>
                                <div style={{ marginBottom: '16px' }}>
                                    <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Delivery fee</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{formatAmount(singleOrder?.data?.order?.shipping?.deliveryFee)}</p>
                                </div>
                                <div style={{ marginBottom: '16px', borderBottom: ' 0.5px solid #E6E7E9', paddingBottom: '16px' }}>
                                    <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Discount</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{singleOrder?.data?.order?.totalDiscount}</p>
                                </div>
                                <div style={{ marginBottom: '16px', }}>
                                    <p className={'f16 boldText'} style={{ color: ' #38383A' }}>Grand total</p>
                                    <p className={'semiBoldText f16'} style={{ color: '#919294' }}>₦{formatAmount(Number(singleOrder?.data?.order?.totalItemsPrice) + Number(singleOrder?.data?.order?.shipping?.deliveryFee))}</p>
                                </div>


                            </CardsWithHeader>
                            <CardsWithHeader title={'Message for restaurant'} icon={Message} >
                                <div>
                                    <p className={'f14 semiBoldText'} style={{ marginBottom: "16px", color: "#38383A" }}>Message</p>
                                    <p className={'f16 regularText'} style={{ fontStyle: 'italic', color: '#999CA0' }}>{singleOrder?.data?.order?.comment}</p>
                                </div>
                            </CardsWithHeader>
                        </>}
                    </section>
                </main>
            }
            {state?.confirmModal && <ConfirmPaymentModal onDecline={closeConfirmModal} orderNo={singleOrder?.data?.order?.orderNo} id={singleOrder?.data?.order?.id} />}
            {state.assignModal && <AssignRiderModal onDecline={closeAssignModal} orderId={singleOrder?.data?.order?.id} id={singleOrder?.data?.order?.id} />}
            {state.agencyModal && <AssignAgencyModal onDecline={closeAssignToAgencyModal} orderId={singleOrder?.data?.order?.id} id={singleOrder?.data?.order?.id} />}
            {state.cancelModal && <CancelOrderModal onDecline={closeCancelModal} orderId={singleOrder?.data?.order?.id} id={singleOrder?.data?.order?.id} />}

        </>
    )
}

export default OrderDetails


