import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../../assets/styles/createProductsStyles.css';
import { getProducts } from '../../../redux/actions/ProductsActions';
import { STORE_PRODUCT } from '../../../redux/Types';
import MultiButton from '../../../utils/MultiButton';
import { showSuccess } from '../../../utils/Toast';
import http from '../../../utils/utils';
import CreateProductForm from './CreateProductForm';
import VerifyProducts from './VerifyProducts';
import { useMediaQuery } from 'react-responsive';
import { BackIcon } from '../../../assets/images/Index';

const CreateProducts = (props) => {

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1200px)' })
    // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' })
    const [currentIndex, setCurrentIndex] = useState(0)
    const [confirm, setConfirm] = useState(false)
    const [business, setBusiness] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [state, setState] = useState({
        product_name: '', product_description: '', product_images: [], product_tags: [], product_options: [],
        tags: '', option_name: '', option_price: '', loading: false,
    })
    const [image, setImage] = useState(null);
    const handleImageChange = async (event) => {
        const { product_images } = state
        setImage(event.target.files[0]);
        const formData = new FormData();
        formData.append("image", event.target.files[0]);
        try {
            const res = await http.post('admin/upload_image', formData)
            console.log(res)
            if (res) {
                product_images.push(res?.data?.image_url)
            }
        } catch (error) {
            console.log(error)
        }



    };
    const updateProductTags = () => {
        const { tags, product_tags } = state
        if (tags !== '') {
            product_tags.push(tags)
            setState({ ...state, tags: '' })
        }
    }

    const updateProductOptions = () => {
        const { product_options, option_name, option_price } = state
        if (option_name !== '' || option_price !== '') {
            product_options.push({ name: option_name, price: Number(option_price) })
            setState({ ...state, option_name: '', option_price: '' })
        }
    }

    const onChangeProductName = (e) => {
        setState({ ...state, product_name: e.target.value })
    }
    const onChangeProductDescription = (e) => {
        setState({ ...state, product_description: e.target.value })
    }

    const onChangeTags = (e) => {
        setState({ ...state, tags: e.target.value })
    }

    const onChangeOptionName = (e) => {
        setState({ ...state, option_name: e.target.value })
    }

    const onChangeOptionPrice = (e) => {
        setState({ ...state, option_price: e.target.value })
    }

    const onChangeBizName = (e) => {
        setState({ ...state, bizName: e.target.value })
    }
    const selectBusiness = (val) => {
        setBusiness(val)
        console.log(val)
    }


    const verifyProduct = () => {
        const { product_description, product_images, product_name, product_options, product_tags } = state
        const product = { product_description, product_images, product_name, product_options, product_tags }
        dispatch({ type: STORE_PRODUCT, payload: product })
    }

    const deleteFromTags = (index) => {
        const newArray = [...state.product_tags];
        newArray.splice(index, 1);
        setState({ ...state, product_tags: newArray })
    };

    const deleteFromOptions = (index) => {
        const newArray = [...state.product_options];
        newArray.splice(index, 1);
        setState({ ...state, product_options: newArray })
    };

    const confirmProduct = () => {
        setConfirm(prevState => !prevState)
    }

    const next = () => {
        setCurrentIndex(currentIndex + 1)
        verifyProduct()
    }
    const back = () => {
        setCurrentIndex(currentIndex - 1)
    }
    const cancel = () => {
        props.onDecline()
    }

    const createProduct = async () => {
        const { product_description, product_images, product_name, product_options, product_tags } = state
        const product = { product_description, product_images, product_name, product_options, product_tags }
        setState({ ...state, loading: true })

        try {
            const res = await http.post(`admin/business/${business?.id}/product/create`, product)
            console.log(res)
            setConfirm(false)
            setState({ ...state, loading: false, })
            props.onDecline()
            showSuccess(res?.message)
            dispatch(getProducts(1))


        } catch (error) {
            console.log(error)
            setState({ ...state, loading: false })

        }
    }

    const ButtonGroups = [
        [
            { label: 'Cancel', onClick: () => cancel(), style: 'Back', backIcon: false, },
            { label: 'Next', onClick: () => next(), style: 'Next', nextIcon: true }
        ],
        [
            { label: 'Back', onClick: () => back(), style: 'Back', backIcon: true, },
            { label: 'Create Product', onClick: () => createProduct(), style: 'Next', nextIcon: true, loading: state.loading, disabled: !confirm }
        ],

    ];
    const renderButtons = () => {
        return ButtonGroups[currentIndex].map(({ label, onClick, style, backIcon, nextIcon, loading, disabled }, i) => (
            <button key={i} onClick={onClick} className={style === 'Back' ? 'createFooterBackBtn' : 'createFooterNextBtn'} disabled={disabled} style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
                {loading ? <span className={'loader'}></span> :
                    <p className={'semiBoldText f14'}>{label}</p>}
            </button>
        ))

    }


    return (
        <>
            <section className={'modalContainer'}>
                <section className={'createProductFormContainer'}>
                    <div className={'createProductHeader'}>
                        {!isDesktopOrLaptop && <img src={BackIcon} alt='icon' style={{ marginRight: "20px" }} onClick={props.onDecline} />}
                        <p className={'boldText'} style={{ color: '#38383A' }}>Create product</p>
                        {isDesktopOrLaptop &&
                            <AiOutlineClose onClick={props.onDecline} />}
                    </div>
                    <div className={'formContent'}>
                        <p className={'f16 boldText'} style={{ color: '#919294', marginBottom: '8px' }}>{currentIndex === 0 ? 'Meal information' : 'Product preview'}</p>
                        {currentIndex === 0 && <CreateProductForm handleImageChange={handleImageChange} image={image} tags={state.tags}
                            option_name={state.option_name} option_price={state.option_price} product_description={state.product_description}
                            product_name={state.product_name} product_tags={state.product_tags} product_options={state.product_options}
                            onChangeProductDescription={onChangeProductDescription} onChangeProductName={onChangeProductName}
                            onChangeOptionName={onChangeOptionName} onChangeOptionPrice={onChangeOptionPrice} onChangeTags={onChangeTags}
                            updateProductOptions={updateProductOptions} updateProductTags={updateProductTags} deleteFromOptions={deleteFromOptions}
                            deleteFromTags={deleteFromTags} business={business} selectBusiness={selectBusiness}

                        />}
                        {currentIndex === 1 && <VerifyProducts confirm={confirm} confirmProduct={confirmProduct} />}

                    </div>
                    <div className={'createProductFooter'}>
                        {renderButtons()}
                    </div>
                </section>
            </section>

        </>
    )
}

export default CreateProducts