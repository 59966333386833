export const RE_DIGIT = new RegExp(/^\d+$/);

export const setToken = async (data) => {
    try {
        const accessToken = data.accessToken;
        localStorage.setItem("accessToken", accessToken);
    } catch (error) {
        console.log("Could not set accessToken ", error.message);
    }
}


export const shortenXterLength = (string, number = 10) => {
    if (string) {
        if (string.length <= number) {
            return string
        }
        return `${string.slice(0, number)}..`
    }
    return ''
}


export const setLocation = async (data) => {
    try {
        localStorage.setItem("location", data);
    } catch (error) {
        console.log("Could not set location ", error.message);
    }
}


export const formatAmount = (amount, maxDigit = 2) => {
    if (Number(amount) === 0 || amount) {
        return Number(amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: maxDigit,
        });
    }

    return '***';
}


export const setSession = (token) => {
    localStorage.setItem('accessToken', token);
    // localStorage.setItem('username', username);
};



export const usersFilter = [
    'All', 'Customers', 'Vendors', 'Riders', 'Waitlist',
]

export const settingsTabs = [
    'Personal information'
]