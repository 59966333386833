import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { connect, useSelector } from 'react-redux';
import { ArrowDown } from '../assets/images/icons';
import { getVendors } from '../redux/actions/OtherActions';
import './utils.css';
const CustomDropDown = (props) => {
    const [open, setOpen] = useState(false)
    const { vendors, gettingVendors } = useSelector(state => state.others)
    const { business, setBusiness, selectBusiness, bizName, onChangeBizName } = props
    const fetchVendors = async () => {
        try {
            const res = await props.getVendors()
            console.log(res)
        } catch (error) {
            console.log(error)
        }
    }
    const toggler = () => {
        fetchVendors()
        setOpen(previousState => !previousState);
    }


    const renderVendors = () => {

        if (gettingVendors) {
            return (
                <>
                    <Skeleton width={'100%'} height={'40px'} style={{ margin: "10px auto" }} />
                    <Skeleton width={'100%'} height={'40px'} />
                    <Skeleton width={'100%'} height={'40px'} style={{ margin: "10px auto" }} />
                    <Skeleton width={'100%'} height={'40px'} />
                </>

            )
        }

        if (vendors && vendors?.data?.businesses.length !== 0) {
            return vendors?.data?.businesses?.map((item, index) => {
                return (
                    <li className={'customSelectListItem regularText f16'}
                        onClick={() => selectBusiness(item)} key={index}
                        style={{ color: '#000000', cursor: 'pointer' }}
                    >{item?.businessName}</li>
                )
            })
        }
    }

    const checkInputState = () => { }

    return (
        <>
            <div className={'flexRow alignCenter justifyBetween customSelectContainer'} onClick={toggler}>
                <div style={{ width: '80%', }} >
                    {/* <p className={'f14 regularText'} style={{ color: 'GrayText' }}>{business?.businessName || 'Select Vendor'}</p> */}
                    <input className={'inputBox'} value={bizName ? bizName : business?.businessName} type={'text'} onChange={onChangeBizName} placeholder={'Select vendor'} />
                </div>
                <div>
                    <img src={ArrowDown} alt={'icon'} />
                </div>
                {open && vendors &&
                    <ul className={'customSelectListContainer'}>
                        {renderVendors()}
                    </ul>
                }
            </div>
        </>
    )
}

export default connect(null, { getVendors })(CustomDropDown);